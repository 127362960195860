@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-shape-divider-bottom-1660663403 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1660663403 svg {
  position: relative;
  display: block;
  width: calc(165% + 1.3px);
  height: 61px;
}

.custom-shape-divider-bottom-1660663403 .shape-fill {
  fill: #ffffff;
}

html {
  font-size: 12px;
}
@media (min-width: 1000px) {
  html {
    font-size: 16px;
  }
}
